import React from "react";

import cx from "classnames";
import { Link } from "react-router-dom";

import Styles from "./Home.module.scss";

import { ReactComponent as Logo } from "src/assets/lqdnlogo.svg";
import { Button, Text } from "src/components";

const Home = () => {
  return (
    <div className={Styles.HomeContainer}>
      <section className={Styles.TitleSection}>
        <Text type="h1">
          Internet
          <br />
          et
          <br />
          libertés
        </Text>
        <Text type="h3">
          15 ans de combat de
          <br />
          La Quadrature du Net
        </Text>
        <div className={Styles.Actions}>
          <Logo className={Styles.LogoLqdn} />
          <a href="La_Quadrature_Du_Net_15_ans_de_combats.epub" target="_blank" rel="noopener noreferrer">
            <Button>epub</Button>
          </a>
          <a href="La_Quadrature_Du_Net_15_ans_de_combats.pdf" target="_blank" rel="noopener noreferrer">
            <Button className={Styles.ButtonDownload}>pdf</Button>
          </a>
        </div>
        <div>
          <Text type="h4">Mathieu Labonde</Text>
          <Text type="h4">Lou Malhuret</Text>
          <Text type="h4">Benoît Piédallu </Text>
          <Text type="h4">Axel Simon</Text>
        </div>
        <Link to="/book/introduction">
          <Button>Commencer la lecture</Button>
        </Link>
      </section>
      <section className={Styles.IndexSection}>
        <Link
          to="/book/introduction"
          className={cx(Styles.Link, Styles.Introduction)}
        >
          <Button variant="big">Introduction</Button>
        </Link>
        <Link to="/book/I/1" className={cx(Styles.Link, Styles.Part1)}>
          <Button variant="big" subtext="(2008-2014)">
            Partie 1<br />
            Défendre Internet
          </Button>
        </Link>
        <Link to="/book/II/1" className={Styles.Link}>
          <Button variant="big" subtext="(2014-2018)">
            Partie 2<br />
            De l’obsession
            <br />
            (anti)terroriste
            <br />
            à la surveillance
            <br />
            des géants du Web
          </Button>
        </Link>
        <Link to="book/III/1" className={cx(Styles.Link, Styles.Part3)}>
          <Button variant="big" subtext="(2018-2022)">
            Partie 3<br />
            L’ère de
            <br />
            la technopolice
          </Button>
        </Link>
        <Link
          to="book/conclusion"
          className={cx(Styles.Link, Styles.Conclusion)}
        >
          <Button variant="big">Conclusion</Button>
        </Link>
      </section>
    </div>
  );
};

export default Home;
